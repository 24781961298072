import './index.scss';
import {Card, Form, Input, Button, message} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import React from 'react';
import {useDispatch} from 'react-redux';
import {fetchLogin} from '@/store/modules/user';
import {useNavigate} from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import logo from '@/assets/images/KMM-logo.png';  // 引入 logo 图片
import CryptoJS from 'crypto-js';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 加密函数
  const encryptPassword = (password) => {
    const key = CryptoJS.enc.Utf8.parse('1234567890123456');

    const iv = CryptoJS.lib.WordArray.random(16); // 生成一个随机 IV
    const encrypted = CryptoJS.AES.encrypt(password, key, {
      iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7,
    });
    // 返回 IV 和加密后的数据
    return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
  };

  // 在 onFinish 中使用加密函数
  const onFinish = async (values) => {
    try {
      const encryptedPassword = encryptPassword(values.password);
      const payload = {
        ...values, password: encryptedPassword,
      };
      const response = await dispatch(fetchLogin(payload));
      if (response && response.status === '200') {
        const decodedToken = jwtDecode(response.data);
        if (decodedToken.role === 'admin') {
          navigate('/adminHome');
        } else if (decodedToken.role === 'user') {
          navigate('/userHome');
        }
        message.success(response.message);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error('ログインに失敗しました');
    }
  };

  return (<div className="login">
    <a href="https://www.kamome-travel.jp/" target="_blank" rel="noopener noreferrer">
      <img className="login-logo" src={logo} alt="Logo" />
    </a>
    <Card className="login-container">
      <div className="login-header">
        {/* <img className="login-logo" src={logo} alt="" /> */}
        {/* 登录表单 */}
        <div className="login-title">カモメグループ精算システム</div>
      </div>
      <Form validateTrigger="onBlur" onFinish={onFinish}>
        <Form.Item name="username"
                   rules={[{
                     required: true, message: 'アカウントを入力してください',
                   }]}>
          <Input size="large" placeholder="アカウントを入力してください" prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item name="password"
                   rules={[{
                     required: true, message: 'パスワードを入力してください',
                   }]}>
          <Input.Password size="large" placeholder="パスワードを入力してください" prefix={<LockOutlined />} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" block>
            ログイン
          </Button>
        </Form.Item>
      </Form>
      <div className="login-help">
        <Button type="link" onClick={() => navigate('/help')}>
          <a>パスワード忘れるはコチラ</a>
        </Button>
      </div>
      <div className="login-footer">Copyright © 2024 KAMOME TOURIST CO., LTD. All Right Reserved.</div>
    </Card>
  </div>);
};

export default Login;