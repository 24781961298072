import React, {useEffect, useState} from 'react';
import {
  EditTwoTone, DeleteTwoTone, EyeTwoTone, SearchOutlined, DeleteOutlined, PlusOutlined, DownloadOutlined,
} from '@ant-design/icons';
import {
  Table, Modal, Input, Popconfirm, Form, Button, Space, message, Descriptions, Select, Row, Col, Spin,
} from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {request} from '@/utils';
import qs from 'qs';
import FileUploadForSecond from '@/pages/AdminHome/AdminSecond/Components/FileUploadForSecond';
import UserEditModal from '@/pages/AdminHome/AdminSecond/Components/UserEditModal';
import ActuarialDetailsModal from '@/pages/UserHome/UserSecond/ActuarialDetailsModal';
import SendMailButton from '@/pages/AdminHome/AdminSecond/Components/SendMailButton';

const App = () => {
  const [dataRecord, setDataRecord] = useState({});   //编辑时的数据记录
  const [form] = Form.useForm();    // 传值表单
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false); // 用于区分编辑和新增
  const [editVisible, setEditVisible] = useState(false);
  const [isActuarialModalVisible, setIsActuarialModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  dayjs.extend(customParseFormat);

  // 表格参数
  const columns = [{
    dataIndex: 'userId', title: '利用者ID',
  }, {
    dataIndex: 'userAccount', title: 'アカウント(電話番号)', width: 110,
  }, {
    dataIndex: 'userEmail', title: 'メールアドレス',
  }, {
    dataIndex: 'payee', title: ' 精算先(利用者) ',
  }, {
    dataIndex: 'finalTotal', title: '総決算金額(精算待ち)', width: 120, render: (amount) => {
      const color = amount < 0 ? 'red' : 'green';
      const formattedAmount = new Intl.NumberFormat('ja-JP').format(amount);
      return <span style={{color}}>{`￥${formattedAmount}`}</span>;
    },
  }, {
    dataIndex: 'bankName', title: '金融機関名',
  }, {
    dataIndex: 'branchCode', title: '支店コード', width: 120,
  }, {
    dataIndex: 'branchName', title: '支店名',
  }, {
    dataIndex: 'accountNumber', title: '口座番号', width: 110,
  }, {
    dataIndex: 'accountHolderName', title: '口座名義人',
  }, {
    dataIndex: 'passwordChanged',
    title: '最終更新日',
    render: (text, record) => (record.passwordChanged ? record.passwordChangeTime : <span
      style={{backgroundColor: 'red', color: 'white', padding: '5px 10px', borderRadius: '4px'}}>認証待ち</span>),
  }, {
    title: (<div>
      操作</div>), fixed: 'middle', render: (_, record) => {
      return (<Space size="middle">
        <Button icon={<EyeTwoTone />} onClick={() => handleDetailShow(record)} />
        <Button icon={<EditTwoTone />} onClick={() => handleOpenModal(record)} />
        <Popconfirm
          title="削除"
          description="削除しますか？"
          onConfirm={() => {
            deleteItems([record.userId]);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            icon={<DeleteTwoTone />}
          />
        </Popconfirm>
      </Space>);
    },
  }];

  // 列表配置
  const items = [{
    key: '1', label: '利用者ID', name: 'userId', children: dataRecord.userId,
  }, {
    key: '2', label: 'アカウント(電話番号)', name: 'userAccount', children: dataRecord.userAccount,
  }, {
    key: '3', label: 'メールアドレス', name: 'userEmail', children: dataRecord.userEmail,
  }, {
    key: '4', label: 'パスワード', name: 'userPassword', children: '********',
  }, {
    key: '5', label: '精算先(利用者)', name: 'payee', children: dataRecord.payee,
  }, {
    key: '6', label: '総決算金額(精算待ち)', name: 'finalTotal', children: dataRecord.finalTotal,
  }, {
    key: '7', label: '金融機関名', name: 'bankName', children: dataRecord.bankName,
  }, {
    key: '8', label: '支店コード', name: 'branchCode', children: dataRecord.branchCode,
  }, {
    key: '9', label: '支店名', name: 'branchName', children: dataRecord.branchName,
  }, {
    key: '10', label: '口座番号', name: 'accountNumber', children: dataRecord.accountNumber,
  }, {
    key: '11', label: '口座名義人', name: 'accountHolderName', children: dataRecord.accountHolderName,
  }, {
    key: '12', label: 'アカウント状態', name: 'isActive', children: dataRecord.isActive ? 'アクティブ' : '非アクティブ',
  }, {
    key: '13',
    label: 'パスワード変更状態',
    name: 'passwordChanged',
    children: dataRecord.passwordChanged ? '変更済み' : '未変更',
  }, {
    key: '14', label: 'パスワード変更日付', name: 'passwordChangeTime', children: dataRecord.passwordChangeTime,
  }];

  // 详细模块可见状态
  const [detailVisible, setDetailVisible] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const deleteItems = async (data) => {

    let ids;
    if (data.length === 1) {
      ids = data;
    } else {
      ids = selectedRowKeys;
    }

    const result = await request.delete('/admin/deleteUser', {
      data: ids,
    });

    if (result.status === '200') {
      message.success(result.data + `件削除しました`);
    } else if (result.status === '404') {
      message.error(`この件はありません`);
    } else if (result.status === '400') {
      message.error(`削除の件を選んでください`);
    }
    setSelectedRowKeys([]);
    setRequestData({
      ...requestData,
    });
  };

  const handleButtonClick = () => {
    const newFilters = {
      userId, payee,
    };

    // 检查是否有变化
    const isFiltersChanged = (newFilters.userId !== requestData.userId || newFilters.payee !== requestData.payee);

    setRequestData(prevTableParams => ({
      ...prevTableParams, ...newFilters, page: isFiltersChanged ? 0 : prevTableParams.page, // 只有条件变化时重置为0
    }));
  };

  const [total, setTotal] = useState(0);

  const [dataList, setDataList] = useState([{
    'userId': '',
    'userAccount': '',
    'userPassword': '',
    'isActive': '',
    'payee': '',
    'finalTotal': '',
    'bankName': '',
    'branchCode': '',
    'branchName': '',
    'accountNumber': '',
    'accountHolderName': '',
    'passwordChanged': '',
    'passwordChangeTime': '',
  }]);

  const [requestData, setRequestData] = useState({
    userId: null, payee: '', page: 0, size: 10,
  });

  useEffect(() => {
    handleSearch();
  }, [requestData]);

  const handleSearch = async () => {
    try {
      setLoading(true);
      // 将参数对象转换为查询字符串
      const queryString = qs.stringify(requestData);
      // 发送请求
      const response = await request.get(`/admin/getAllUsers?${queryString}`);
      if (response.status === '200') {
        // 更新分页参数
        setDataList(response.data.users);
        setTotal(response.data.totalItems);
      }
    } catch (error) {
      console.error('Error searching data:', error);
    } finally {
      setLoading(false); // 结束加载
    }
  };

  // 表格参数变化
  const handleTableChange = (pageObject) => {
    setRequestData(prevTableParams => ({
      ...prevTableParams, page: pageObject.current - 1, size: pageObject.pageSize,
    }));
  };


  // 点击详细按钮
  const handleDetailShow = (record) => {

    // 设置列表值
    setDataRecord(record);

    // 设置编辑表单值
    form.setFieldsValue({
      ...record,
    });
    setDetailVisible(true);
  };

  // 检索条件输入
  const [userId, setUserId] = useState('');
  const [payee, setPayee] = useState('');

  const handleOpenModal = (record) => {
    if (record) {
      setIsEditMode(true);
      setDataRecord(record);
      form.setFieldsValue({
        ...record,
      });
    } else {
      setIsEditMode(false);
      form.resetFields();
    }
    setEditVisible(true);
  };

  const handleDetailClose = () => {
    setDetailVisible(false); // 关闭确认弹窗
  };

  // 确认修改
  const handleEditOK = () => {
    form.validateFields()
      .then(async (values) => {
        try {
          let response;
          if (isEditMode) {
            values.userId = dataRecord.userId;
            response = await request.post('/admin/updateUser', values);
          } else {
            response = await request.post('/admin/createUser', values);
          }

          if (response.status === '200') {
            message.success(isEditMode ? '修正しました' : '追加しました');
            handleSearch(); // 成功后调用查询接口刷新数据
            setEditVisible(false); // 关闭 Modal
          } else if (response.status === '400') {
            message.error('電話番号またはガイド名が既に存在しています');
            // 如果你需要在表单字段中显示更具体的错误信息，你可以在这里设置表单的字段错误提示
            form.setFields([{
              name: 'phoneNumber', // 这是一个示例字段名，你可以根据实际情况进行调整
              errors: ['电话番号或导游名已存在'],
            }, // 添加更多字段错误信息
            ]);
          } else {
            message.error('修正できません');
            setEditVisible(false); // 关闭 Modal
          }
        } catch (error) {
          console.error('Failed:', error);
          message.error('フォームのエラーを確認してください。');
          // 不关闭 Modal
        }
      })
      .catch((errorInfo) => {
        console.error('Failed:', errorInfo);
        // 校验失败，显示错误信息，不关闭 Modal
        message.error('フォームのエラーを確認してください。');
      });
  };

  const styledItems = items.map(item => {
    let contentStyle = {fontWeight: 'bold'};
    let children = item.children;

    if (item.label === '総決算金額(精算待ち)') {
      const amount = parseFloat(children);
      const formattedAmount = new Intl.NumberFormat('ja-JP').format(amount);
      children = `￥${formattedAmount}`;
      if (amount < 0) {
        contentStyle.color = 'red';
      } else {
        contentStyle.color = 'green';
      }
    }

    return {
      ...item, contentStyle, children,
    };
  });

  const handleActuarialModalCancel = () => {
    setIsActuarialModalVisible(false);
  };

  //导出excel
  const exportExcel = async () => {
    setLoading(true);
    let url = `/admin/downloadUserExcel`;

    await request.get(url, {
      responseType: 'blob', // 确保响应类型为 blob
    }).then(res => {
      // 创建下载的链接
      const url = window.URL.createObjectURL(new Blob([res], // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式
        {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
      const link = document.createElement('a');
      link.href = url;
      // 从header中获取服务端命名的文件名
      link.setAttribute('download', 'ガイドリスト.xlsx');
      document.body.appendChild(link);
      link.click();
      message.success('発行成功');
    });
    setLoading(false);
  };

  return (<div>
    <div style={{marginBottom: 16}}>
      <span>ID検索:</span>
      <Input placeholder="" onChange={e => setUserId(e.target.value)} style={{
        width: '5%', marginRight: '20px', marginLeft: '5px',
      }} />
      <span>精算先(利用者)検索:</span>
      <Input placeholder="山田" onChange={e => setPayee(e.target.value)} style={{
        width: '10%', marginRight: '20px', marginLeft: '5px',
      }} />
      <Button onClick={handleButtonClick} style={{marginRight: '40px'}} type="primary" icon={<SearchOutlined />}>
        検索
      </Button>
      <Button onClick={deleteItems} style={{marginRight: '20px'}} type="primary" icon={<DeleteOutlined />}>
        削除
      </Button>
      <Button onClick={() => handleOpenModal(null)} style={{marginRight: '20px'}} type="primary"
              icon={<PlusOutlined />}>
        新規ガイド
      </Button>
      <FileUploadForSecond />
      <Button onClick={() => exportExcel()} style={{marginRight: '20px'}} type="primary" icon={<DownloadOutlined />}
      > ダウンロード
      </Button>
      <SendMailButton selectedUserIds={selectedUserIds} total={total} />
    </div>
    <Spin spinning={loading}>
    <Table
      columns={columns}
      rowKey={(record) => record.userId}
      dataSource={dataList}
      pagination={{
        current: requestData.page + 1,
        pageSize: requestData.size,
        total: total,
        showTotal: (total) => `計 ${total} 件`,
        showSizeChanger: true,
        showQuickJumper: true,
        onChange: (page, pageSize) => {
          setRequestData((prev) => ({
            ...prev, page: page - 1, size: pageSize,
          }));
        },
        locale: {
          jump_to: "按回车跳至",
          jump_to_confirm: "确定",
        },
      }}
      loading={loading}
      onChange={handleTableChange}
      // 设置多选
      rowSelection={{
        fixed: 'left', // type: 'checkbox',
        columnWidth: 25, selectedRowKeys, onChange: (selectedRowKeys) => {
          setSelectedUserIds(selectedRowKeys);
          setSelectedRowKeys(selectedRowKeys);
        }, preserveSelectedRowKeys: true,
      }}
    />
    </Spin>
    <Modal
      title="詳細情報"
      open={detailVisible}
      onOk={handleDetailClose}
      onCancel={handleDetailClose}
      footer={null}
      width={1000}
    >
      <Descriptions
        size="small"
        layout="vertical"
        bordered
        column={{
          xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4,
        }}
      >
        {styledItems.map(item => (<Descriptions.Item
          key={item.key}
          label={item.label}
          labelStyle={{fontWeight: 'bold'}}
          contentStyle={item.contentStyle}
        >
          {item.children}
        </Descriptions.Item>))}
      </Descriptions>
      <Button
        type="primary"
        onClick={() => setIsActuarialModalVisible(true)}
        style={{backgroundColor: '#1890ff', borderColor: '#1890ff', margin: '20px auto 0'}}
        className="actuarial-details-button1"
      >
        精算金額詳細
      </Button>
      <ActuarialDetailsModal
        visible={isActuarialModalVisible}
        onCancel={handleActuarialModalCancel}
        actuarialDetails={dataRecord}
      />
    </Modal>

    <UserEditModal
      visible={editVisible}
      isEditMode={isEditMode}
      form={form}
      handleOk={handleEditOK}
      handleCancel={() => setEditVisible(false)}
    />
  </div>);
};
export default App;