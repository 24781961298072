import React, {useEffect, useState} from 'react';
import {
  EditTwoTone, DeleteTwoTone, EyeTwoTone, DownloadOutlined, SearchOutlined, DeleteOutlined, PlusOutlined,
} from '@ant-design/icons';
import {
  Table, Modal, Input, Popconfirm, Form, Button, Space, message, Select, Tag, Row, Col, DatePicker, AutoComplete, Spin,
} from 'antd';
import {request} from '@/utils';
import qs from 'qs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import DetailModal from '@/components/DetailModal';
import FileUploadForHome from '@/pages/AdminHome/Components/FileUploadForHome';
import SendMailButtonForHome from '@/pages/AdminHome/Components/SendMailButtonForHome';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Tokyo');

const App = () => {
  const [loading, setLoading] = useState(false);

  const columns = [{
    dataIndex: 'id', title: '精算ID', width: 70, // 设置列宽
  }, {
    dataIndex: 'storeName', title: '店舗名', ellipsis: true, // 超过长度截断处理
    width: 100, // 设置列宽
  }, {
    dataIndex: 'tourName', title: 'ツアー名', ellipsis: true, width: 175,
  }, {
    dataIndex: 'visitDate', title: '入店日', width: 100,
  }, {
    dataIndex: 'paymentType', title: '精算種類', ellipsis: true, width: 85,
  }, {
    dataIndex: 'amount', title: '金額', render: (amount) => {
      const color = amount < 0 ? 'red' : 'black';
      const formattedAmount = new Intl.NumberFormat('ja-JP').format(amount);
      return <span style={{color}}>{`￥${formattedAmount}`}</span>;
    }, width: 90,
  }, {
    dataIndex: 'carryOver', title: '繰越', render: (amount) => {
      const color = amount < 0 ? 'red' : 'black';
      const formattedAmount = new Intl.NumberFormat('ja-JP').format(amount);
      return <span style={{color}}>{`￥${formattedAmount}`}</span>;
    }, width: 90,
  }, {
    dataIndex: 'paymentFee', title: '精算手数料', render: (amount) => {
      const color = amount < 0 ? 'red' : 'black';
      const formattedAmount = new Intl.NumberFormat('ja-JP').format(amount);
      return <span style={{color}}>{`￥${formattedAmount}`}</span>;
    }, width: 93,
  }, {
    dataIndex: 'paymentDate', title: '精算日', ellipsis: true, width: 100, // 设置列宽
  }, {
    dataIndex: 'payer', title: '精算者', ellipsis: true, width: 130, // 设置列宽
  }, //   {
    //   dataIndex: 'guideName', title: 'ガイド名', ellipsis: true, width: 90,
    // },
    {
      dataIndex: 'userId', title: '利用者ID', width: 83, // 设置列宽
    }, {
      dataIndex: 'payee', title: '精算先(利用者)', ellipsis: true, width: 80,
    }, {
      dataIndex: 'paymentStatus', title: '精算状態', render: (text, record) => {
        if (selectedRowKeys.includes(record.id)) {
          return (<Select
            defaultValue={text}
            style={{width: 98, fontSize: '12px'}} // 添加 fontSize 样式
            onChange={value => {
              // 更新 record 的 paymentStatus
              record.paymentStatus = value;

              // 更新表单的 paymentStatus，确保同步
              form.setFieldsValue({paymentStatus: value});

              // 仅在所有值更新后再调用 handleEditOK
              handleEditOK();
            }}
          >
            <Option value="精算済み" style={{fontSize: '14px', color: '#52c41a'}}>精算済み</Option>
            <Option value="精算待ち" style={{fontSize: '14px', color: '#faad14'}}>精算待ち</Option>
          </Select>);
        }
        return (<Tag
          color={text === '精算済み' ? 'success' : text === '精算待ち' ? 'warning' : 'default' // 默认样式
          }
          onClick={() => {
            setSelectedRowKeys([record.id]);
            setIsEditMode(true);
            setDataRecord(record);
          }}
          style={{
            fontSize: '14px', color: text ? undefined : '#8c8c8c', // 如果 text 为空，则使用灰色文本
            backgroundColor: text ? undefined : '#f5f5f5', // 如果 text 为空，则使用浅灰色背景
            border: text ? undefined : '1px dashed #d9d9d9', // 如果 text 为空，则使用虚线边框
          }}
        >
          {text || '未設定✖'} {/* 如果 text 为空，显示 '未設定' */}
        </Tag>);
      }, width: 108,
    }, {
      title: '操作', width: 135, fixed: 'right', render: (_, record) => {
        return (<Space size="middle">
          <Button icon={<EyeTwoTone />} onClick={() => handleDetailShow(record)} />
          <Button icon={<EditTwoTone />} onClick={() => handleOpenModal(record)} />
          <Popconfirm
            title="削除"
            description="削除しますか？"
            onConfirm={() => {
              deleteItems([record.id]);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteTwoTone />} />
          </Popconfirm>
        </Space>);
      },
    }];

  const [dataRecord, setDataRecord] = useState({});   //编辑时的数据记录
  const [form] = Form.useForm();    // 传值表单
  const [isEditMode, setIsEditMode] = useState(false); // 用于区分编辑和新增
  const [editVisible, setEditVisible] = useState(false);
  const [selectedActuarialIds, setSelectedActuarialIds] = useState([]);


  // 列表配置
  const items = [{
    key: '1', label: '店舗名', name: 'storeName', children: dataRecord.storeName,
  }, {
    key: '2', label: 'ツアー名', name: 'tourName', children: dataRecord.tourName,
  }, {
    key: '3', label: '入店日', name: 'visitDate', children: dataRecord.visitDate,
  }, {
    key: '4', label: '精算種類', name: 'paymentType', children: dataRecord.paymentType,
  }, {
    key: '5', label: '金額', name: 'amount', children: dataRecord.amount !== null ? dataRecord.amount : 0,
  }, {
    key: '6', label: '繰越', name: 'carryOver', children: dataRecord.carryOver !== null ? dataRecord.carryOver : 0,
  }, {
    key: '7', label: '精算手数料', name: 'paymentFee', children: dataRecord.paymentFee !== null ? dataRecord.paymentFee : 0,
  }, {
    key: '8', label: '精算日', name: 'paymentDate', children: dataRecord.paymentDate,
  }, {
    key: '9', label: '精算者', name: 'payer', children: dataRecord.payer,
  }, {
    key: '10', label: 'ガイド名', name: 'guideName', children: dataRecord.guideName,
  }, {
    key: '11', label: '利用者ID', name: 'userId', children: dataRecord.userId,
  }, {
    key: '12', label: '精算先(利用者)', name: 'payee', children: dataRecord.payee,
  }, {
    key: '13', label: '精算状態', name: 'paymentStatus', children: dataRecord.paymentStatus,
  }, {
    key: '14', label: '備考欄', name: 'remarks', children: dataRecord.remarks,
  }];

  // 详细模块可见状态
  const [detailVisible, setDetailVisible] = useState(false);

  // 多选功能，selectedRows 是一个状态，用于存储当前选中的行
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const {Option, OptGroup} = Select;
  // 检索条件输入
  const [searchField, setSearchField] = useState('id'); // 默认选择'guideName'
  const [searchValue, setSearchValue] = useState(''); // 输入框的值
  const [selectedLabel, setSelectedLabel] = useState('精算ID');

  const deleteItems = async (data) => {
    let ids;
    if (data.length === 1) {
      ids = data;
    } else {
      ids = selectedRowKeys;
    }

    const result = await request.delete('/admin/deleteActuarialData', {
      data: ids,
    });

    if (result.status === '200') {
      message.success(result.data + `件削除しました`);
    } else if (result.status === '404') {
      message.error(`この件はありません`);
    } else if (result.status === '400') {
      message.error(`削除の件を選んでください`);
    }
    setSelectedRowKeys([]);
    setRequestData({
      ...requestData,
    });
  };

  const handleButtonClick = () => {
    setRequestData({
      ...cleanRequestData, [searchField]: searchValue, page: 0,
    });
  };

  //导出excel
  const exportExcel = async () => {
    setLoading(true);
    let url;
    if (selectedRowKeys && selectedRowKeys.length > 0) {
      url = `/admin/downloadActuarialExcel?ids=${selectedRowKeys.join(',')}`;
    } else {
      const queryString = qs.stringify(requestData);
      url = `/admin/downloadActuarialExcel?${queryString}`;
    }

    await request.get(url, {
      responseType: 'blob', // 确保响应类型为 blob
    }).then(res => {
      // 创建下载的链接
      const url = window.URL.createObjectURL(new Blob([res], // 设置该文件的mime类型，这里对应的mime类型对应为.xlsx格式
        {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
      const link = document.createElement('a');
      link.href = url;
      // 从header中获取服务端命名的文件名
      const fileName = '1213';
      link.setAttribute('download', '精算リスト.xlsx');
      document.body.appendChild(link);
      link.click();
      message.success('発行成功');
    });
    setLoading(false);
  };

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFieldChange = (value, option) => {
    setSearchField(value);
    setSearchValue(''); // 重置输入框的值
    let newLabel;
    if (option.children === '精算済み' || option.children === '精算待ち') {
      newLabel = '精算状態';
    } else if (option.children === '仮払' || option.children === '仮払精算' || option.children === 'リベート') {
      newLabel = '精算種類';
    } else {
      newLabel = option.children;
    }
    setSelectedLabel(newLabel);
  };

  const handleSelect = (value) => {
    const [field, optionValue] = value.split('|');
    setSearchField(field);
    setSearchValue(optionValue);
    setRequestData({
      ...cleanRequestData, [field]: optionValue, page: 0,
    });
  };

  const handleDetailClose = () => {
    setDetailVisible(false); // 关闭确认弹窗
  };

  const [total, setTotal] = useState(0);

  const [dataList, setDataList] = useState([{
    'id': '',
    'paymentType': '',
    'storeName': '',
    'tourName': '',
    'visitDate': '',
    'amount': '',
    'carryOver': '',
    'paymentFee': '',
    'paymentDate': '',
    'payer': '',
    'guideName': '',
    'payee': '',
    'paymentStatus': '',
    'remarks': '',
    'uploadAt': '',
    'isDeleted': false,
  }]);

  const [requestData, setRequestData] = useState({
    id: null, guideName: '', page: 0, size: 10,
  });

  const [cleanRequestData, setCleanRequestData] = useState({
    id: null, guideName: '', page: 0, size: 10,
  });

  useEffect(() => {
    handleSearch();
  }, [requestData]);

  const handleSearch = async () => {
    try {
      setLoading(true);
      //清空选择行
      setSelectedRowKeys([]);
      // 将参数对象转换为查询字符串
      const queryString = qs.stringify(requestData);
      // 发送请求
      const response = await request.get(`/admin/getAllActuarialList?${queryString}`);
      if (response.status === '200') {
        // 更新分页参数
        setDataList(response.data.actuarialList);
        setTotal(response.data.totalItems);
      }
    } catch (error) {
      console.error('Error searching data:', error);
    } finally {
      setLoading(false); // 结束加载
    }
  };

  // 表格参数变化
  const handleTableChange = (pageObject) => {
    setRequestData(prevTableParams => ({
      ...prevTableParams, page: pageObject.current - 1, size: pageObject.pageSize,
    }));
  };

  // 点击详细按钮
  const handleDetailShow = (record) => {
    // 设置列表值
    setDataRecord(record);
    // 设置编辑表单值
    form.setFieldsValue({
      ...record,
      visitDate: record.visitDate ? dayjs(record.visitDate) : null,
      paymentDate: record.paymentDate ? dayjs(record.paymentDate) : null,
    });
    setDetailVisible(true);
  };


  const handleOpenModal = (record) => {
    if (record) {
      setIsEditMode(true);
      setDataRecord(record);
      form.setFieldsValue({
        ...record,
        visitDate: record.visitDate ? dayjs(record.visitDate) : null,
        paymentDate: record.paymentDate ? dayjs(record.paymentDate) : null,
      });
    } else {
      setIsEditMode(false);
      form.resetFields();
    }
    setEditVisible(true);
  };

  // 确认修改     handleSearch();
  const handleEditOK = () => {
    form.validateFields()
      .then(async (values) => {
        // 手动添加 paymentStatus
        values.paymentStatus = form.getFieldValue('paymentStatus') || dataRecord.paymentStatus;

        // 日期格式化
        values.visitDate = values.visitDate ? dayjs(values.visitDate).format('YYYY-MM-DD') : null;
        values.paymentDate = values.paymentDate ? dayjs(values.paymentDate).format('YYYY-MM-DD') : null;
        // 数值转换
        values.amount = values.amount ? parseFloat(values.amount) : null;
        values.paymentFee = values.paymentFee ? parseFloat(values.paymentFee) : null;
        values.carryOver = values.carryOver ? parseFloat(values.carryOver) : null;

        if (isEditMode) {
          values.id = dataRecord.id;
          const editResult = await request.post('/admin/updateActuarial', values);
          if (editResult.status === '200') {
            message.success('修正しました');
            handleSearch(); // 成功后调用查询接口刷新数据
          } else {
            message.error('修正できません');
          }
        } else {
          const addResult = await request.post('/admin/createActuarialData', values);
          if (addResult.status === '200') {
            message.success('追加しました');
            handleSearch(); // 成功后调用查询接口刷新数据
          } else {
            message.error('追加できません');
          }
        }
        setEditVisible(false); // 确保在校验通过并且数据提交后才关闭 Modal
      })
      .catch((errorInfo) => {
        console.error('Failed:', errorInfo);
        // 校验失败，显示错误信息，不关闭 Modal
        message.error('フォームのエラーを確認してください');
      });
  };

  //遍历检索payee
  const [options, setOptions] = useState([]);
  const [cachedList, setCachedList] = useState([]);

  // 当输入框获得焦点时触发
  const handleFocus = async () => {
    if (cachedList.length === 0) { // 只在未缓存时请求数据
      try {
        const response = await request.get(`/admin/searchPayee`);
        if (response.status === '200') {
          setCachedList(response.data);
          setOptions(response.data.map(payee => ({value: payee})));
        } else {
          message.warning(response.message);
        }
      } catch (error) {
        message.error('リストの取得に失敗しました。後でもう一度お試しください。');
      }
    } else {
      setOptions(cachedList.map(payee => ({value: payee})));
    }
  };

  // 输入时根据缓存的列表进行筛选
  const handlePayeeChange = (value) => {
    const filteredOptions = cachedList
      .filter(payee => payee.includes(value))
      .map(payee => ({value: payee}));

    setOptions(filteredOptions);
  };

  // 自定义验证逻辑
  const validatePayee = (_, value) => {
    if (!value) {
      return Promise.resolve(); // 如果输入为空，直接通过验证
    }

    const initialValue = form.getFieldValue('payee'); // 获取当前字段的初始值

    if (value === initialValue) {
      return Promise.resolve(); // 如果输入框的值与初始值相同，直接通过验证
    }

    const isValid = cachedList.some(payee => payee === value);
    if (!isValid) {
      return Promise.reject('この名前が一致しないか、まだ作成されていません。');
    }

    return Promise.resolve();
  };

  return (<div>
    <div style={{marginBottom: 16, display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
      <span>検索:</span>
      <div style={{display: 'flex', alignItems: 'center', marginRight: '20px', marginLeft: '5px'}}>
        <Select
          value={selectedLabel}
          defaultValue="id"
          onChange={handleFieldChange}
          style={{width: '110px'}}
          onSelect={handleSelect}
        >
          <Option value="id">精算ID</Option>
          <Option value="userId">利用者ID</Option>
          <Option value="guideName">ガイド名</Option>
          <Option value="payer">精算者</Option>
          <Option value="tourName">ツアー名</Option>
          <Option value="storeName">店舗名</Option>
          <OptGroup label="精算種類">
            <Option value="paymentType|仮払">仮払</Option>
            <Option value="paymentType|仮払精算">仮払精算</Option>
            <Option value="paymentType|リベート">リベート</Option>
          </OptGroup>
          <OptGroup label="精算状態">
            <Option value="paymentStatus|精算済み">精算済み</Option>
            <Option value="paymentStatus|精算待ち">精算待ち</Option>
          </OptGroup>
        </Select>
        <Input
          placeholder="木村"
          value={searchValue}
          onChange={handleInputChange}
          style={{width: '200px', marginLeft: '5px'}}
        />
      </div>
      <Button onClick={handleButtonClick} style={{marginRight: '40px'}} type="primary" icon={<SearchOutlined />}
      > 検索
      </Button>
      <Popconfirm
        title="削除しますか？"
        onConfirm={deleteItems}
        okText="はい"
        cancelText="いいえ"
      >
        <Button
          style={{marginRight: '20px'}}
          type="primary"
          icon={<DeleteOutlined />}
        >
          削除
        </Button>
      </Popconfirm>
      <Button onClick={() => handleOpenModal(null)} style={{marginRight: '20px'}} type="primary" icon={<PlusOutlined />}
      > 新規精算
      </Button>
      <FileUploadForHome />
      <Button onClick={() => exportExcel()} style={{marginRight: '20px'}} type="primary" icon={<DownloadOutlined />}
      > ダウンロード
      </Button>
      <SendMailButtonForHome selectedActuarialIds={selectedActuarialIds} total={total} requestData={requestData} />
    </div>
    <Spin spinning={loading}>
    <Table
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={dataList}
      pagination={{
        current: requestData.page + 1,
        pageSize: requestData.size,
        total: total,
        showTotal: (total) => `計 ${total} 件`,
        showSizeChanger: true,
        showQuickJumper: true,
        onChange: (page, pageSize) => {
          setRequestData((prev) => ({
            ...prev, page: page - 1, size: pageSize,
          }));
        },
        locale: {
          jump_to: "按回车跳至",
          jump_to_confirm: "确定",
        },
      }}
      loading={loading}
      onChange={handleTableChange}
      // 设置多选
      rowSelection={{
        fixed: 'left', columnWidth: 25, selectedRowKeys, onChange: (selectedRowKeys) => {
          setSelectedActuarialIds(selectedRowKeys);
          setSelectedRowKeys(selectedRowKeys);
        }, preserveSelectedRowKeys: true,
      }}
    />
    </Spin>
    <DetailModal
      detailVisible={detailVisible}
      handleDetailClose={handleDetailClose}
      items={items}
    />
    <Modal
      title={isEditMode ? '編集' : '新規精算'}
      width={1000}
      open={editVisible}
      onOk={handleEditOK}
      onCancel={() => setEditVisible(false)}
      closable={true} // 允许通过点击 "X" 关闭
      maskClosable={false} // 禁止点击遮罩层关闭
    >
      <Form name="user-form" form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="店舗名" name="storeName" rules={[{required: false, message: '店舗名を入力してください'}]}
                       hasFeedback>
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="ツアー名" name="tourName"
                       rules={[{required: false, message: 'ツアー名を入力してください'}]} hasFeedback>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="入店日" name="visitDate" rules={[{required: false, message: '入店日を入力してください'}]}
                       hasFeedback>
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="精算種類" name="paymentType"
                       rules={[{required: false, message: '精算種類を入力してください'}]} hasFeedback>
              <Select>
                <Select.Option value="仮払">仮払</Select.Option>
                <Select.Option value="仮払精算">仮払精算</Select.Option>
                <Select.Option value="リベート">リベート</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="金額" name="amount"
                       rules={[{required: false, message: '金額は必須です'}, {
                         pattern: /^-?\d+(\.\d{1,2})?$/, message: '数値を入力してください（小数点以下は最大2桁まで）',
                       }]} hasFeedback>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="繰越" name="carryOver"
                       rules={[{required: false, message: '繰越は必須です'}, {
                         pattern: /^-?\d+(\.\d{1,2})?$/, message: '数値を入力してください（小数点以下は最大2桁まで）',
                       }]} hasFeedback>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="精算手数料" name="paymentFee"
                       rules={[{required: false, message: '精算手数料は必須です'}, {
                         pattern: /^-?\d+(\.\d{1,2})?$/, message: '数値を入力してください（小数点以下は最大2桁まで）',
                       }]} hasFeedback>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="精算日" name="paymentDate"
                       rules={[{required: false, message: '精算日を入力してください'}]} hasFeedback>
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="精算者" name="payer" rules={[{required: true, message: '精算者を入力してください'}]}
                       hasFeedback>
              <Select>
                <Select.Option value="ドリームジャパン（株）">ドリームジャパン（株）</Select.Option>
                <Select.Option value="カモメツーリスト（株）">カモメツーリスト（株）</Select.Option>
                <Select.Option value="（株）ワンズライフジャパン">（株）ワンズライフジャパン</Select.Option>
                <Select.Option value="ＪＴＭホールディングス（株）">ＪＴＭホールディングス（株）</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="ガイド名" name="guideName"
                       rules={[{required: false, message: 'ガイド名を入力してください'}]} hasFeedback>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="精算先" name="payee"
                       rules={[{required: false, message: '精算先を入力してください'}, {validator: validatePayee}, // 自定义验证规则
                       ]}
                       hasFeedback>
              <AutoComplete
                options={options}
                onFocus={handleFocus} // 输入框获得焦点时触发
                onChange={handlePayeeChange} // 输入时触发
                placeholder="精算先を入力してください"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="精算状態" name="paymentStatus"
                       rules={[{required: false, message: '精算状態を入力してください'}]} hasFeedback>
              <Select>
                <Select.Option value="精算済み">精算済み</Select.Option>
                <Select.Option value="精算待ち">精算待ち</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="備考欄" name="remarks"
                       rules={[{max: 600, message: '備考欄は600字以内で入力してください'}]}>
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  </div>);
};
export default App;